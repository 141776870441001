<template>
  <section class="attention-requests">
    <Filters
      class="mb-6 chat-filter"
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <Chat :filters="filters" :key="key" />
  </section>
</template>

<script>
import Chat from './chat/Chat'

export default {
  data() {
    return {
      filterModel: [],
      filters: {
        bots: [],
        versions: []
      },
      key: new Date().toString()
    }
  },
  components: {
    Chat,
    Filters: () => import('../metrics/filters/Filters.vue')
  },
  methods: {
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'queue':
          this.filters.queues = filter.value
          break
        default:
          break
      }
      this.key = new Date().toString()
    }
  },
  mounted() {
    this.filterModel = [
      {
        name: 'queueFilter',
        component: 'QueueFilter',
        width: 10,
        props: {
          allowEmpty: false,
          startAll: true
        }
      }
    ]
  }
}
</script>

<style lang="scss">
.chat-filter {
  z-index: 3100;
  position: relative;
}
</style>
