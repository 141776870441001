<template>
  <div class="scroll" id="chatList">
    <div>
      <!-- CHAT BY QUERY PARAM -->
      <div v-if="chatByQuery">
        <div class="text-primary pl-4 pr-4 pb-2 pt-2">
          <span class="title">
            {{ lang.attentionRequests.sidenav.selected[languageSelected] }}
            (1)
          </span>
        </div>
        <div class="chat__contacts">
          <ul class="chat__contacts">
            <li
              class="cursor-pointer hovered"
              @click="updateActiveChat(chatByQuery)"
            >
              <ChatContact
                :contact="chatByQuery"
                :unseenMsg="chatByQuery.unseenMsg"
                :selectedCategory="selectedCategory"
              />
            </li>
          </ul>
        </div>
      </div>

      <Accordion>
        <!-- ACTIVE CHATS -->
        <AccordionItem :opened="!chatByQuery" ref="chatByQuery">
          <template slot="accordion-trigger">
            <div class="text-primary">
              <span class="title">
                {{
                  lang.attentionRequests.sidenav.activeChats[languageSelected]
                }}
                ({{ activeChats.length }})
              </span>
            </div>
          </template>
          <template slot="accordion-content">
            <ChatsActive
              :key="attentionChatKey"
              :activeChats="activeChats"
              @updateActiveChatUser="updateActiveChat"
            />
          </template>
        </AccordionItem>
      </Accordion>

      <!-- PENDING CHATS SEARCH FILTER -->
      <div class="search-bar-wrapper">
        <vs-input
          icon="icon-search"
          icon-pack="feather"
          list="browsers"
          class="input-rounded-full no-icon-border w-full"
          :placeholder="
            lang.attentionRequests.searchPlaceholder[languageSelected]
          "
          v-model="search"
          @input="debounceSearch"
        />
      </div>

      <!-- PENDING CHATS -->
      <div class="text-primary pl-4">
        <span class="title" id="pending-list">
          {{ lang.attentionRequests.sidenav.otherChats[languageSelected] }}
          <span v-show="pendingChatsCount !== null">
            &nbsp;({{ pendingChatsCount }})
          </span>
        </span>
        <PendingChatFilter
          :categoryOptions="categoryOptions"
          :pendingCount="pendingChatsCount"
          :selectedCategory.sync="selectedCategory"
          @onCategorySelect="onCategorySelect"
        />
      </div>

      <!-- <div v-if="filteredPendingChats.length && search" class="ml-6">
        {{ filteredPendingChats.length }}
        {{ lang.attentionRequests.resultsFoundFor[languageSelected] }}
        <strong>"{{ search }}"</strong>
      </div> -->

      <div class="chat__list">
        <InfiniteScroll
          :items="filteredPendingChats"
          item-key="_id"
          @refetch="showMore"
        >
          <template slot="item" slot-scope="{ item }">
            <div @click="updateActiveChat(item)" class="cursor-pointer hovered">
              <ChatContact
                :key="item._id"
                :contact="item"
                :unseenMsg="item.unseenMsg"
                v-if="item && item.wasTaked !== true && item.status !== 0"
                :highlight="search"
                :selectedCategory="selectedCategory"
              />
            </div>
          </template>
        </InfiniteScroll>

        <!-- NO RESULTS MESSAGE -->
        <div
          v-if="!filteredPendingChats.length && !loading"
          class="list-message"
        >
          <i class="material-icons"> search </i>
          <span class="ml-2">
            {{ lang.general.noDataText[languageSelected] }}
          </span>
        </div>

        <!-- ALL CHATS SHOWN MESSAGE -->
        <div
          v-if="filteredPendingChats.length && isLastPage"
          class="list-message"
        >
          <i class="material-icons"> check_circle_outline </i>
          <span class="ml-2">
            {{ lang.attentionRequests.allChatsShown[languageSelected] }}
          </span>
        </div>

        <!-- LOADING MORE SPINNER -->
        <div class="show-more-button" v-show="loading">
          <div
            class="vs-con-loading__container loading-more"
            :class="{ activeLoading: loading }"
            :id="['loading-border']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash.debounce'
import ContactService from '@/services/contact.service'
import BotMakerService from '@/services/botMaker.service'
import { USER_ROLES } from '@/models/Roles/UserRoles'
import konecta from '@/services/konecta'

const DEBOUNCE_TIME = 300

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'ChatList',
  props: {
    filters: Object
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      // data
      chats: [], //activeChats + chatByquery (computed props)
      pendingChatsCount: null,
      categoryOptions: [],
      selectedCategory: { query: '' },
      statusCount: {
        '[XY]: pending': 0,
        '[YZ]: pending': 0,
        '[RELEASED]: chat released': 0
      },
      totalCount: null,
      bots: [],
      queues: [],
      // keys
      attentionChatKey: new Date().toString(),
      pendingChatKey: new Date().toString(),
      showActiveChats: true,
      showPendingChats: true,
      statusPendingKey: new Date().getTime() * 3,

      // requests
      search: '',
      limit: 20,
      skip: 0,

      loading: false
    }
  },
  components: {
    ChatContact: () => import('./chat-list/ChatContact.vue'),
    PendingChatFilter: () => import('./chat-list/PendingChatFilter.vue'),
    // PendingChats: () => import('./chat-list/ChatPending.vue'),
    ChatsActive: () => import('./chat-list/ChatAttention.vue'),
    InfiniteScroll: () => import('@/components/InfiniteScroll.vue'),
    Accordion: () =>
      import('../../../../../components/kona-accordion/KonaAccordion.vue'),
    AccordionItem: () =>
      import('../../../../../components/kona-accordion/KonaAccordionItem.vue')
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      async handler() {
        await this.resetSearch()
        this.updateChatByQuery()
      }
    },
    languageSelected() {
      this.buildCategoryFilter()
    },
    async takeoverPending() {
      this.$refs.chatByQuery.expand()
      setTimeout(() => {
        document
          .querySelector('.bg-success-gradient')
          .scrollIntoView({ behavior: 'smooth' })
      }, 1)
    },
    newChatUserName() {
      this.updateContactName()
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'session',
      'activeChatUser',
      'chatGeneralSettings',
      'takeoverPending',
      'newChatUserName',
      'session',
      'useStaffManagement'
    ]),
    chatByQuery() {
      if (!this.$route.query.chat) return
      return this.chats.find(chat => chat._id === this.$route.query.chat)
    },
    activeChats() {
      return this.chats.filter(
        chat =>
          chat.status === 2 &&
          (!this.chatByQuery || chat._id !== this.$route.query.chat)
      )
    },
    pendingChats() {
      const activeChatsIds = this.activeChats.map(ac => ac._id)
      return this.chats.filter(
        c => c._id !== this.$route.query.chat && !activeChatsIds.includes(c._id)
      )
    },
    filteredPendingChats() {
      if (!this.search) return this.pendingChats

      const currentSearch = this.search.toLowerCase()
      return this.pendingChats.filter(
        chat =>
          chat.botName.toLowerCase().includes(currentSearch) ||
          chat.fullname.toLowerCase().includes(currentSearch) ||
          chat.platform.toLowerCase().includes(currentSearch) ||
          chat.intents
            .join(',')
            .toLowerCase()
            .includes(currentSearch)
      )
    },
    isLastPage() {
      return this.totalCount === this.chats.length - this.activeChats.length
    }
  },
  methods: {
    ...mapActions('contact', ['getChats', 'getPendingChats']),
    ...mapActions(['updateChatstoSubscribe']),
    ...mapMutations([
      'UPDATE_ACTIVE_CHAT',
      'UPDATE_ACTIVE_MESSAGES',
      'UPDATE_SIDEBAR_ITEMS_MIN',
      'SELECT_BOT'
    ]),
    getQueryParams(statusActive, chatByQuery) {
      // paginado solo para pending chats (no para active chats)
      let filter = !statusActive ? `skip=${this.skip}&limit=${this.limit}` : ''

      // status seleccionado
      filter += !statusActive
        ? this.selectedCategory && this.selectedCategory.query
          ? `&where[priorityEvent][$in]=${this.selectedCategory.query}`
          : ''
        : ''
      // pending chats
      filter +=
        !statusActive && !chatByQuery
          ? `&where[status][$nin]=2&where[status][$nin]=0&where[status][$exists]=true`
          : ''

      // chat en query
      const queryChat = this.$route.query.chat
      if (queryChat) {
        filter += `&where[chat][$ne]=${queryChat}`
        filter += chatByQuery ? `&where[_id]=${queryChat}` : ''
      }

      // active chats para el user actual
      const userId = this.session.user._id || this.session.user.id
      filter += statusActive ? `&where[status]=2&where[user]=${userId}` : ''

      // queues
      if (!statusActive && !chatByQuery && this.filters.queues) {
        this.filters.queues.forEach(
          queueId => (filter += `&where[takeoverQueues][$in]=${queueId}`)
        )
      }

      // texto en search bar
      if (!statusActive && !chatByQuery && this.search.length) {
        const anonymousUser = this.lang.attentionRequests.sidenav.anonymousUser[
          this.languageSelected
        ].toLowerCase()
        filter += anonymousUser.includes(this.search.toLowerCase())
          ? `&where[$or][0][fullname][$regex]=${this.search}&where[$or][0][fullname][$options]=i&where[$or][1][fullname][$exists]=false`
          : `&where[fullname][$regex]=${this.search}&where[fullname][$options]=i`
      }

      if (this.selectedCategory.subpath) {
        return `/${this.selectedCategory.subpath}?${filter}`
      }

      return filter
    },
    async getPeople(statusActive = false, chatByQuery = false) {
      // si no se inicializaron los bots o
      // si es chat by query pero no hay chat en url
      
      if (
        (!this.filters && !this.filters.queues && !this.filters.queues.length && !statusActive && !chatByQuery) ||
        (chatByQuery && !this.$route.query.chat) ||
        (this.isLastPage && this.skip !== 0)
      )
        return

      const queryParams = this.getQueryParams(statusActive, chatByQuery)
      this.loading = true
      if (this.chats && this.chats.length > 0) {
        this.$vs.loading({
          container: '#loading-border',
          type: 'border',
          background: 'transparent'
        })
      }
      try {
        let response = null;
        if (!statusActive && !chatByQuery) {
          response = await this.getPendingChats(queryParams) 
        } else {
          response = await this.getChats(queryParams)
        }

        if (response && response.data) {
          if (response.data.result) {
            const anonymousUser = this.lang.attentionRequests.sidenav
              .anonymousUser[this.languageSelected]

            let parsedChats = response.data.result
            parsedChats.forEach(c => {
              c.fullname = c.fullname || anonymousUser
              c.intents = c.intents || []
            })
            // this.chats = this.chats.concat(parsedChats)
            // para evitar duplicados por eventos de socket
            this.chats = [...new Set([...this.chats, ...parsedChats])]
            const ids = {}
            this.chats = this.chats.filter(item => {
              // filter dups
              if (!ids[item._id]) {
                ids[item._id] = true
                return true
              }
              return false
            })
          }

          if (!this.selectedCategory || !this.selectedCategory.query) {
            this.pendingChatsCount = !chatByQuery && !statusActive
              ? response.data.length
              : this.pendingChatsCount - 1 > -1
              ? this.pendingChatsCount - 1
              : 0
          }

          this.totalCount = !statusActive ? response.data.length : null
          this.getStatusCount(response.data.priorityCount)
          this.buildCategoryFilter()
          this.loading = false
          this.$vs.loading.close('#loading-border > .con-vs-loading')
        }
      } catch (error) {
        console.error('error', error)
        if (error.message !== 'cancelled') {
          this.loading = false
          this.$vs.loading.close('#loading-border > .con-vs-loading')
          this.$vs.notify({
            title: 'Error',
            text: this.lang.attentionRequests.errorGetPeople[
              this.languageSelected
            ],
            color: 'danger'
          })
        }
      }
    },
    showMore() {
      if (this.loading) return
      this.skip += this.limit
      this.getPeople() // get pending chats
    },
    async resetSearch() {
      this.skip = 0
      const loadActiveChats = this.skip === 0 && !this.chats.length
      this.chats = this.activeChats
      // this.totalCount = null
      
      if (loadActiveChats) await this.getPeople(true) // get active chats
      await this.getPeople() // get pending chats
      if (this.$route.query.chat) await this.getPeople(false, true)
      this.updateSocketEvents()
    },
    debounceSearch: debounce(function() {
      this.resetSearch()
    }, DEBOUNCE_TIME),
    onlyAgent() {
      return (
        this.session.user.roles.ids.indexOf(USER_ROLES.AGENT) > -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPERVISOR) === -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPER_ADMIN) === -1
      )
    },
    async getBots() {
      let query = this.onlyAgent() ? `?as[role]=${USER_ROLES.AGENT}` : ''
      if (
        this.useStaffManagement
        && !this.isAuthorized({ key: 'konecta.bot_maker.edit_bot_settings' })
      ) {
        query = `?as[role]=${USER_ROLES.AGENT}`
      }

      const response = await konecta.get('/bot' + query)
      this.bots = response.data.map(element => {
        return {
          _id: element._id,
          name: element.service.name,
          service: element.service,
          company: element.company,
          platform: 'any',
          showEmotions: element.showEmotions
        }
      })
    },
    async getQueues() {
      const response = await konecta.get('/queue')
      this.queues = response.data;
    },
    async getNewChat(params) {
      try {
        const response = await ContactService.getChat(params.chat, params.service)
        let newChat = response.data
          && response.data.length === 1
          && response.data.result[0]
          ? response.data.result[0]
          : null
        if (!newChat) return null

        if (!newChat.fullname) {
          newChat.fullname = this.lang.attentionRequests.sidenav.anonymousUser[
            this.languageSelected
          ]
        }

        const botResponse = await BotMakerService.getBot(params.bot)
        const _bot = botResponse.data
        if (_bot) {
          newChat.botName = _bot.service.name
          newChat.service = _bot.service._id
        }
        return newChat
      } catch (_) {
        return null
      }
    },

    // CATEGORIES
    getStatusCount(priorityCount) {
      this.statusCount = {
        '[XY]: pending': 0,
        '[YZ]: pending': 0,
        '[RELEASED]: chat released': 0
      }
      priorityCount = priorityCount || []
      priorityCount.forEach(pc => {
        if (pc._id) this.statusCount[pc._id] = pc.count
      })
    },
    buildCategoryFilter() {
      const xThresholdMinutes = this.chatGeneralSettings.xThreshold / 60 / 1000
      const yThresholdMinutes = this.chatGeneralSettings.yThreshold / 60 / 1000

      this.categoryOptions = [
        {
          title: this.lang.attentionRequests.sidenav.categoryFilter.options.all[
            this.languageSelected
          ],
          icon: 'list',
          iconPack: 'material',
          query: '',
          count: this.pendingChatsCount
        },
        {
          title: this.lang.attentionRequests.sidenav.categoryFilter.options
            .sortedByPriority[this.languageSelected],
          icon: 'priority_high',
          iconPack: 'material',
          subpath: 'taggedPriority',
          query: '',
          count: this.pendingChatsCount
        },
        {
          title: this.lang.attentionRequests.sidenav.categoryFilter.options
            .released[this.languageSelected],
          icon: 'send',
          iconPack: 'material',
          query: '[RELEASED]: chat released',
          count: this.statusCount['[RELEASED]: chat released'] || 0
        },
        {
          title: `${
            this.lang.attentionRequests.sidenav.categoryFilter.options
              .yThreshold.pre[this.languageSelected]
          } ${yThresholdMinutes} ${
            this.lang.attentionRequests.sidenav.categoryFilter.options
              .yThreshold.post[this.languageSelected]
          }`,
          icon: 'whatshot',
          iconPack: 'material',
          query: '[YZ]: pending',
          count: this.statusCount['[YZ]: pending'] || 0
        },
        {
          title: `${
            this.lang.attentionRequests.sidenav.categoryFilter.options
              .xThreshold.pre[this.languageSelected]
          } ${xThresholdMinutes} ${
            this.lang.attentionRequests.sidenav.categoryFilter.options
              .xThreshold.and[this.languageSelected]
          } ${yThresholdMinutes} ${
            this.lang.attentionRequests.sidenav.categoryFilter.options
              .xThreshold.post[this.languageSelected]
          }`,
          icon: 'schedule',
          iconPack: 'material',
          query: '[XY]: pending',
          count: this.statusCount['[XY]: pending'] || 0
        }
      ]
    },
    async onCategorySelect(category) {
      this.selectedCategory = category
      this.resetSearch()
    },

    // EVENTS
    updateActiveChat(contact) {
      const chatIndex = this.chats.findIndex(c => c._id === contact._id)
      let chatUpdate = this.chats[chatIndex]
      chatUpdate.unseenMsg = 0
      this.$set(this.chats, chatIndex, chatUpdate)

      let chat = contact
      chat.index = chatIndex
      chat.unseenMsg = 0
      const botIndex = this.bots.findIndex(b => b._id === chat.bot)
      this.SELECT_BOT(this.bots[botIndex])
      this.UPDATE_ACTIVE_CHAT(chat)
      this.$emit('updateActiveChatUser')

      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      if (
        this.activeChatUser &&
        this.activeChatUser.sender &&
        (this.activeChatUser.sender !== chat.sender ||
          this.activeChatUser.service !== chat.service)
      ) {
        this.$socket.client.emit('leave', {
          type: 'sender',
          token,
          service: this.activeChatUser.service,
          sender: this.activeChatUser.sender,
          bot: this.activeChatUser.bot,
        })
      }
      this.$socket.client.emit('join', {
        type: 'sender',
        token,
        service: chat.service,
        sender: chat.sender,
        bot: chat.bot,
      })
    },
    updateChatByQuery() {
      if (!this.chatByQuery) return
      this.updateActiveChat(this.chatByQuery)
    },

    // SOCKET EVENTS
    updateSocketEvents() {
      console.info('updating senders')
      const aux = this.chats
        .filter(
          chat =>
            chat.status === 2 &&
            chat.using &&
            chat.using._id === (this.session.user._id || this.session.user.id)
        )
        .map(chat => ({
          sender: chat.sender,
          service: chat.service,
          bot: chat.bot
        }))
      // chats seleccionados sin tomar pero visibles en la sidebar
      if (
        this.activeChatUser &&
        aux
          .map(element => `${element.service}${element.sender}`)
          .indexOf(
            `${this.activeChatUser.service}${this.activeChatUser.sender}`
          ) === -1
      ) {
        aux.push({
          sender: this.activeChatUser.sender,
          service: this.activeChatUser.service,
          bot: this.activeChatUser.bot,
        })
      }

      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      this.$socket.client.emit('updateSenders', {
        token,
        senders: aux
      })

      this.updateChatstoSubscribe(aux)
    },

    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr // for testing
    },
    async changeChatTaggedPriority(data) {
      console.info('#changeChatTaggedPriority from component')
      if (
        !this.selectedCategory ||
        this.selectedCategory.subpath !== 'taggedPriority'
      ) {
        let chatUpdateIndex = this.chats.findIndex(chat => chat._id === data.chat);
        if (chatUpdateIndex >= 0) {
          let chatUpdate = this.chats[chatUpdateIndex]
          chatUpdate.ts = data._lastMessageAt
          this.$set(this.chats, chatUpdateIndex, chatUpdate)
        }
        return
      }
      let findChat = false
      // busco chat
      this.chats.forEach((chat, index) => {
        if (chat._id === data.chat) findChat = true
        let chatUpdate = this.chats[index]

        if (chat._id === data.chat) {
          chatUpdate.taggedPriority = data.taggedPriority
          chatUpdate.taggedPriorityEvent = data.taggedPriorityEvent
          chatUpdate.ts = data._lastMessageAt
          this.$set(this.chats, index, chatUpdate)
          let newArrayChats = this.chats
          const newIndex = newArrayChats.findIndex(
            item =>
              (!item.taggedPriority ||
                item.taggedPriority === chat.taggedPriority) &&
              item.status !== 2 &&
              (!this.chatByQuery || this.chatByQuery._id !== item._id)
          )
          const currentIndex = newArrayChats.findIndex(
            item => item._id === chat._id
          )
          if (newIndex !== -1 && newIndex !== currentIndex) {
            newArrayChats = this.array_move(
              newArrayChats,
              currentIndex,
              newIndex
            )
          }
          this.chats = newArrayChats
        }
      })

      if (!findChat) {
        // if it was not found, then get the chat add add it to the list
        let newArrayChats = this.chats
         const qs = {
          bot: data.bot, 
          service: data.service, 
          senderId: data.sender,
          platform: data.platform,
          chat: data.chat
        }

        const newChat = await this.getNewChat(qs)
        if (!newChat) return // no se encontro el chat
        if (!newChat.taggedPriority) {
          newArrayChats = newArrayChats.concat(newChat)
        } else {
          const index = newArrayChats.findIndex(
            item =>
              !item.taggedPriority ||
              item.taggedPriority === newChat.taggedPriority
          )
          if (index === -1) {
            newArrayChats = newArrayChats.concat(newChat)
          } else {
            newArrayChats.splice(index, 0, newChat)
          }
        }
        // const ids = {}
        // newArrayChats = newArrayChats.filter((item) => { // filter dups
        //   if (!ids[item._id]) {
        //     ids[item._id] = true
        //     return true
        //   }
        //   return false
        // });
        this.chats = newArrayChats
        let chatUpdate = this.chats[0]
        chatUpdate.wasTaked = false
        this.$set(this.chats, 0, chatUpdate)
        this.pendingChatsCount++
        this.buildCategoryFilter()
      }

      this.updateSocketEvents()
    },
    async changeChatStatus(data) {
      console.info('#changeChatStatus from component')
      let findChat = false
      // busco chat
      this.chats.forEach((chat, index) => {
        if (chat._id === data.chat) findChat = true
        let chatUpdate = this.chats[index]
        const userId = this.session.user._id || this.session.user.id

        if (userId === data.adminOragent) {
          // YO EMITÍ EL EVENTO
          if (chat._id === data.chat) {
            // let chatUpdate = this.chats[index]
            chatUpdate.status = data.status
            chatUpdate.using = data.user
            chatUpdate.taggedPriority = data.taggedPriority
            chatUpdate.taggedPriorityEvent = data.taggedPriorityEvent
            chatUpdate.ts = new Date();
            this.$set(this.chats, index, chatUpdate)

            if (
              this.activeChatUser &&
              this.activeChatUser.bot === data.bot &&
              this.activeChatUser.sender === data.sender
            ) {
              this.UPDATE_ACTIVE_CHAT(chat)
              this.$emit('updateActiveChatUser')
            }
          }
        } else {
          // OTRO EMITIÓ EL EVENTO
          if (chat._id === data.chat) {
            if (
              data.status === 2 &&
              !(
                this.$route.query.bot &&
                this.$route.query.chat &&
                this.$route.query.chat === chat._id
              )
            ) {
              // let chatUpdate = this.chats[index]
              chatUpdate.wasTaked = true
              chatUpdate.using = data.user
              this.$set(this.chats, index, chatUpdate)
              if (
                this.activeChatUser &&
                data.chat === this.activeChatUser._id
              ) {
                this.UPDATE_ACTIVE_CHAT(null)
                this.UPDATE_ACTIVE_MESSAGES([])
                this.$vs.notify({
                  color: 'warning',
                  title: this.lang.attentionRequests.sidenav.takenByAnotherUser
                    .title[this.languageSelected],
                  text: this.lang.attentionRequests.sidenav.takenByAnotherUser
                    .text[this.languageSelected]
                })
              }
            } else if (data.status === 2) {
              // let chatUpdate = this.chats[index]
              chatUpdate.status = data.status
              chatUpdate.wasTaked = true
              chatUpdate.using = data.user
              chatUpdate.ts = data._lastMessageAt
              this.$set(this.chats, index, chatUpdate)
            } else {
              // let chatUpdate = this.chats[index]
              chatUpdate.status = data.status
              chatUpdate.wasTaked = false
              chatUpdate.using = data.user
              chatUpdate.taggedPriority = data.taggedPriority
              chatUpdate.taggedPriorityEvent = data.taggedPriorityEvent
              chatUpdate.ts = data._lastMessageAt
              this.$set(this.chats, index, chatUpdate)
            }
          }
        }
      })

      if (!findChat) {
        // si no se encontró el chat, obtenerlo y añadirlo
        const qs = {
          bot: data.bot, 
          service: data.service, 
          senderId: data.sender,
          platform: data.platform,
          chat: data.chat
        }
        let newArrayChats = this.chats
        const newChat = await this.getNewChat(qs)
        if (!newChat) return // no se encontro el chat
        if (
          !this.selectedCategory ||
          this.selectedCategory.subpath !== 'taggedPriority'
        ) {
          newArrayChats = [newChat].concat(newArrayChats)
        } else {
          const index = newArrayChats.findIndex(
            item =>
              (!item.taggedPriority ||
                item.taggedPriority === newChat.taggedPriority) &&
              item.status !== 2 &&
              (!this.chatByQuery || this.chatByQuery._id !== item._id)
          )
          // console.log('index', index, newArrayChats[index])
          if (index === -1) {
            newArrayChats = newArrayChats.concat(newChat)
          } else {
            newArrayChats.splice(index, 0, newChat)
          }
          const ids = {}
          newArrayChats = newArrayChats.filter(item => {
            // filter dups
            if (!ids[item._id]) {
              ids[item._id] = true
              return true
            }
            return false
          })
        }
        this.chats = newArrayChats
        let chatUpdate = this.chats[0]
        chatUpdate.wasTaked = false
        this.$set(this.chats, 0, chatUpdate)
        this.pendingChatsCount++
        this.buildCategoryFilter()
        // this.UPDATE_ACTIVE_CHAT(chatUpdate)
        // const element = document.querySelector('#pending-list')
        // element.scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else if (data.status === 2) {
        this.pendingChatsCount--
      }

      this.updateSocketEvents()
    },
    updateUnseenMsg(data) {
      this.chats.some((chat, index) => {
        if (data.bot === chat.bot && data.sender === chat.sender) {
          let chatUpdate = this.chats[index]
          chatUpdate.ts = data.message._createdAt
          if (
            !this.activeChatUser ||
            this.activeChatUser.sender !== chat.sender
          ) {
            // si el chat no esta abierto
            if (!isNaN(chatUpdate.unseenMsg)) {
              chatUpdate.unseenMsg = chatUpdate.unseenMsg + 1
            } else {
              chatUpdate.unseenMsg = 1
            }
          }
          this.$set(this.chats, index, chatUpdate)
          return true
        }
      })
    },
    async changeChatPriority(data) {
      // check if chat exists before getting _id
      const chatIndex = this.chats.findIndex(c => c._id === data.chat)
      const newPriority = data.priority.event
      const currentPriorityIndex = data.priorityHistory.findIndex(
        ph => ph.priority === data.priority.value
      )
      const currentPriority =
        currentPriorityIndex > 0
          ? data.priorityHistory[currentPriorityIndex - 1].meta.event
          : 'unknownPriority'
      let oldPriority = null
      if (chatIndex === -1) {
        if (newPriority === currentPriority) {
          const qs = {
            bot: data.bot, 
            service: data.service, 
            senderId: data.sender,
            platform: data.platform,
            chat: data.chat
          }
          const newChat = await this.getNewChat(qs)
          if (!newChat) return
          this.chats = this.chats.concat(newChat)
        }
      } else if (newPriority !== currentPriority) {
        // priority of visible chat outdated
        oldPriority = currentPriority
        this.$set(this.chats[chatIndex], 'outdatedPriority', true)
      }
      this.updateStatusCount(newPriority, oldPriority)
      this.buildCategoryFilter()
    },
    updateStatusCount(newPriority, oldPriority = null) {
      if (newPriority) this.statusCount[newPriority] += 1
      if (oldPriority) this.statusCount[oldPriority] -= 1
    },
    updateContactName() {
      const chatIndex = this.chats.findIndex(
        c => c._id === this.newChatUserName._id
      )
      let chatUpdate = this.chats[chatIndex]
      chatUpdate.fullname = this.newChatUserName.fullname
      this.$set(this.chats, chatIndex, chatUpdate)
    },
    async reloadFirstTime() {
      // TODO: FIX
      // workaround to make the sockets work first time page is loaded
      const reloadedPending = localStorage.getItem('reloadedPending')
      if (reloadedPending === null || reloadedPending === 'false') {
        this.UPDATE_SIDEBAR_ITEMS_MIN(false)
        await this.$nextTick()
        localStorage.setItem('reloadedPending', 'true')
        location.reload()
      } else {
        localStorage.setItem('reloadedPending', 'false')
      }
    }
  },
  async mounted() {
    // const reloadedPending = localStorage.getItem('reloadedPending')
    // if (reloadedPending === null || reloadedPending === 'false') {
    //   this.UPDATE_SIDEBAR_ITEMS_MIN(false)
    //   await this.$nextTick()
    //   localStorage.setItem('reloadedPending', 'true')
    //   location.reload()
    // } else {
    //   localStorage.setItem('reloadedPending', 'false')
    // }
    await this.getQueues();
    this.$socket.client.on('changeChatStatus', this.changeChatStatus)
    this.$socket.client.on(
      'changeChatTaggedPriority',
      this.changeChatTaggedPriority
    )
    this.$socket.client.on('message', this.updateUnseenMsg)
    this.$socket.client.on('changeChatPriority', this.changeChatPriority)
  },
  beforeDestroy() {
    // if (localStorage.setItem('reloadedPending', 'false')) {
    this.$socket.client.off('changeChatStatus')
    this.$socket.client.off('changeChatTaggedPriority')
    this.$socket.client.off('message')
    this.$socket.client.off('changeChatPriority')
    // }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#chatList {
  .search-bar-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 10px 15px 20px;
  }
  .vs-collapse {
    padding: 0 0 10px 0;
  }
  .chat__list {
    .list-message {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      color: #adadad;
      font-size: 16px;
    }
    i {
      font-size: 16px;
    }
  }
  .text-primary .title {
    font-size: 1.1rem;
  }
  .search-input {
    width: calc(100% - 25px);
  }
  .hovered:hover {
    background: #f8f8f8;
  }
  .show-more-button {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #dae1e7;
    display: flex;
    justify-content: center;
    .loading-more {
      width: 80px;
      height: 80px;
      transition: all 0.3s ease;
    }
  }
  .disabled {
    background: #f1f1f1;
    color: aliceblue;
  }
  .con-content--item {
    padding: 0px !important;
  }
  .w-96 {
    width: 96% !important;
  }

  .category {
    .multiselect {
      min-width: 190px;
      z-index: 999 !important;
      width: unset;
      height: 40px;
    }

    .multiselect__content {
      font-size: 0.9rem;
    }

    .multiselect__content-wrapper {
      max-height: 360px !important;
      background: rgba(255, 255, 255, 0.5);
      border-top: 1px solid #e8e8e8;
    }

    .multiselect__input {
      font-size: 1rem;
    }
    .multiselect__single {
      font-size: 1rem;
    }

    .multiselect__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;

      section {
        display: flex;
        align-items: center;
      }

      .material-icons {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .multiselect--active:not(.multiselect--above) .multiselect__tags {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .multiselect__tags {
      height: 40px;
      padding: 8px 30px 0 8px;
    }

    .multiselect__select {
      height: 40px;
      padding: 8px 4px 8px 8px;
    }

    .multiselect__option--highlight {
      background: transparent !important;
      color: inherit;
      font-weight: bold;
      border-radius: 8px;
    }

    .multiselect__option--selected {
      background: inherit;
      color: inherit;
      border-radius: 8px;

      &.multiselect__option--highlight {
        background: transparent;
        color: inherit;
        border-radius: 8px;
      }
    }

    .multiselect__element {
      background: white;
      border-bottom: 1px solid #dae1e7;
      .con-vs-chip {
        min-height: 32px;
        min-width: 32px;
        .vs-chip--text {
          color: #ffffff;
        }
      }

      &:nth-of-type(1) {
        // background: #ccedff;
        color: #266df6;

        .con-vs-chip {
          background: #266df6;
        }
      }
      &:nth-of-type(2) {
        // background: #f9e7ff;
        color: #8e24aa;

        .con-vs-chip {
          background: #8e24aa;
        }
      }
      &:nth-of-type(3) {
        // background: #fceff2;
        color: #ca3859;

        .con-vs-chip {
          background: #ca3859;
        }
      }
      &:nth-of-type(4) {
        // background: #fff4cc;
        color: #ffc107;

        .con-vs-chip {
          background: #ffc107;
        }
      }
    }
  }
}
</style>
