<template>
  <form class="wrapper" @submit.prevent="sendMessage">
    <AttachFiles />
    <emoji-picker ref="emojiPicker" @emoji="append" :search="search">
      <div
        class="emoji-invoker"
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        @click.stop="clickEvent"
      >
        <svg
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
          />
        </svg>
      </div>
      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
        <div class="emoji-picker" style="bottom: 40px">
          <div class="emoji-picker__search">
            <input type="text" v-model="search" v-focus />
          </div>
          <div>
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <h5>{{ category }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  @click="insert(emoji)"
                  :title="emojiName"
                  >{{ emoji }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </emoji-picker>

    <vs-input
      :disabled="inputDisabled"
      :color="chatStatusColor"
      class="flex-1 input-message"
      required
      :placeholder="
        lang.attentionRequests.chatForm.messageInputPlaceholder[
          languageSelected
        ]
      "
      v-model="input"
      ref="input"
    />
    <vs-button
      :color="chatStatusColor"
      :disabled="buttonDisabled"
      class="bg-primary-gradient ml-4"
      type="filled"
    >
      <span
        v-show="!buttonDisabled"
        v-text="lang.attentionRequests.chatForm.sendMessage[languageSelected]"
      ></span>
      <span
        v-show="buttonDisabled"
        v-text="
          lang.attentionRequests.chatForm.sendingMessage[languageSelected]
        "
      ></span>
    </vs-button>
  </form>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import EmojiPicker from 'vue-emoji-picker'

import AttachFiles from './chat-input/AttachFiles.vue'
import AttentionRequestsService from '../../../../services/attentionRequests.service'
import BotMakerService from '../../../../services/botMaker.service'

export default {
  name: 'chat-input',
  components: {
    EmojiPicker,
    AttachFiles
  },
  data() {
    return {
      input: '',
      search: '',
      inputDisabled: false,
      buttonDisabled: false
    }
  },
  computed: {
    chatStatusColor() {
      if (this.activeChatUser.status == 1) return 'warning'
      if (this.activeChatUser.status == 2) return 'success'
      if (this.activeChatUser.status == 3) return 'danger'
    },
    ...mapState([
      'lang',
      'languageSelected',
      'activeChatUser',
      'activeChatMessages',
      'bot',
      'attachmentFile',
      'suggestedChatMessage'
    ])
  },
  methods: {
    ...mapActions(['verifyMessage', 'sendTextMessage']),
    ...mapMutations([
      'UPDATE_ACTIVE_MESSAGES',
      'UPDATE_ATTACHMENT_FILE',
      'SET_SUGGESTED_CHAT_MESSAGE'
    ]),
    append(emoji) {
      if (!this.attachmentFile || !this.attachmentFile.name) {
        this.input = `${this.input || ''}${emoji}`
      }
      // this.$refs.emojiPicker.toggle()
    },
    autoScrollBottom() {
      setTimeout(() => {
        var el = document.getElementById('chatLog')
        if (el) {
          try {
            document.getElementById('chatLog').scrollTop =
              document.getElementById('chatLog').offsetHeight +
              document.getElementById('chatLog').scrollTop +
              document.getElementById('chatLog').scrollHeight
          } catch (e) {
            console.log(e)
          }
        }
      }, 10)
    },
    async sendMessage() {
      this.buttonDisabled = true
      this.inputDisabled = true
      if (this.attachmentFile.name) {
        this.sendFile() // enviar archivo
      } else {
        try {
          // enviar mensaje simple
          const simpleMessage = {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender,
            input: this.input
          }
          await this.sendTextMessage(simpleMessage)
          this.input = ''
          this.buttonDisabled = false
          this.inputDisabled = false
          this.$nextTick(() =>
            this.$refs.input.$el.children[0].children[0].focus()
          )
          this.SET_SUGGESTED_CHAT_MESSAGE(null)
        } catch (error) {
          console.error(error)
          this.$vs.notify({
            title: 'Oops!',
            text: this.lang.attentionRequests.chatForm.bucketError[
              this.languageSelected
            ],
            color: 'danger',
            position: 'top-right'
          })
          this.buttonDisabled = false
          this.inputDisabled = false
        }
      }
    },
    sendFile() {
      const formData = new FormData()
      formData.append('file', this.attachmentFile)
      const uploadEndpoint = `/bucket/${this.activeChatUser.bot}/${this.activeChatUser.sender}`

      AttentionRequestsService.sendFile(uploadEndpoint, formData)
        .then(async response => {
          const simpleMessage = {
            bot: this.activeChatUser.bot,
            senderId: this.activeChatUser.sender,
            input: response.data.url
          }
          try {
            await this.sendTextMessage(simpleMessage)
            this.input = ''
            this.buttonDisabled = false
            this.inputDisabled = false
          } catch (error) {
            this.$vs.notify({
              title: 'Oops!',
              text: this.lang.attentionRequests.chatForm.bucketError[
                this.languageSelected
              ],
              color: 'danger',
              position: 'top-right'
            })
          }
        })
        .catch(error => {
          console.error(error)
          this.$vs.notify({
            title: 'Oops!',
            text: this.lang.attentionRequests.chatForm.bucketError[
              this.languageSelected
            ],
            color: 'danger',
            // iconPack: 'feather',
            position: 'top-right'
            // icon: 'icon-check-circle'
          })
          this.input = ''
          this.buttonDisabled = false
          this.inputDisabled = false
          this.UPDATE_ATTACHMENT_FILE({})
        })
    },
    // },
    // sockets: {
    agentResponse(data) {
      const message = data.message
      this.verifyMessage(message.input[0]).then(async response => {
        let text = response
        let messages = this.activeChatMessages
        if (
          (this.activeChatUser.bot == data.bot,
          this.activeChatUser.sender == data.sender)
        ) {
          let existingMessage = messages.find(m => m._id === message._id)
          if (!existingMessage) {
            let userResponse
            try {
              userResponse = await BotMakerService.getUser(message.user)
            } catch (e) {
              console.error(e)
            }
            existingMessage = messages.find(m => m._id === message._id)
            if (!existingMessage) {
              messages.push({
                _id: message._id,
                isUser: 0,
                text,
                ts: new Date(),
                user: userResponse && userResponse.data,
                videoCall: message.videoCall,
                _videoCallExpiresAt: message._videoCallExpiresAt,
                input_type: message.input_type
              })
            }
          }
        }
        this.input = ''
        this.UPDATE_ACTIVE_MESSAGES(messages)
        this.autoScrollBottom()
        this.buttonDisabled = false
        this.inputDisabled = false
        this.UPDATE_ATTACHMENT_FILE({})
      })
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      }
    }
  },
  watch: {
    activeChatUser() {
      this.input = ''
      this.serach = ''
      this.inputDisabled = false
      this.buttonDisabled = false
    },
    attachmentFile() {
      this.input = this.attachmentFile.name
      if (this.attachmentFile.name) {
        this.inputDisabled = true // activar input
      } else {
        this.inputDisabled = false // desactivar input
      }
    },
    suggestedChatMessage() {
      if (this.suggestedChatMessage) this.input = this.suggestedChatMessage
    }
  },
  mounted() {
    this.$socket.client.on('agentResponse', this.agentResponse)
  },
  beforeDestroy() {
    this.$socket.client.off('agentResponse')
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/vuesax/apps/chat.scss';

.attention-requests .wrapper {
  position: relative;
  display: inline-block;
  transition: 0.5s;
}

.attention-requests .regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
}

.attention-requests .emoji-invoker {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 7px 15px 7px 7px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 10;
}
.attention-requests .emoji-invoker:hover {
  transform: scale(1.1);
}
.attention-requests .emoji-invoker > svg {
  fill: #b1c6d0;
}

.attention-requests .emoji-picker {
  position: absolute;
  margin-bottom: 3rem;
  z-index: 1;
  font-family: Montserrat, sans-serif;
  border: 1px solid #ccc;
  width: 25rem;
  height: 20rem;
  overflow: scroll;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.attention-requests .emoji-picker__search {
  display: flex;
  margin-bottom: 0.5rem;
}
.attention-requests .emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.attention-requests .emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.attention-requests .emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.attention-requests .emoji-picker .emojis:after {
  content: '';
  flex: auto;
}
.attention-requests .emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.attention-requests .emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.attention-requests .input-message {
  width: 100%;
}

.attention-requests .input-message input,
.attention-requests .input-message .input-span-placeholder {
  padding-left: 70px !important;
}

.attention-requests .icon-large .feather {
  height: 40px !important;
  width: 40px !important;
}

.attention-requests .icon-large .feather:hover {
  transform: scale(1.1);
}
</style>
